import { DEL_ITEM_PERMISSION, MAINTAIN_PERMISSION, GETDATA_PERMISSION, WEB_ANALYTICS_VERSION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("list-composer", { attrs: { "create-button-text": "Add New", "title": "Maintain Versions/Iterations", "search-by": "Version,Comment,ForecastingType", "ph-search-by": "Search by Version, Comment and Forecasting Process", "has-active": false, "has-filter-inactive": false, "config-delete": _vm.configDel, "row-key": "iterationId" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("span", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_vm._v("Show Iterations for Country")]), _c("div", { staticClass: "country-filter mr-4" }, [_c("select-filter", { key: "Country", attrs: { "data-source": _vm.countries, "source": "country", "source-label": _vm.generateCountryLabel, "default-active-first-option": "" }, on: { "change": _vm.onCountryFilterChange } })], 1), _c("div", { staticClass: "d-flex mr-2 align-items-center h-100" }, [_c("span", { staticClass: "mr-2" }, [_vm._v("Show Deleted Iterations?")]), _c("a-checkbox", { attrs: { "default-checked": false, "align": "center", "width": 85 } })], 1)];
  }, proxy: true }, { key: "create-buttons", fn: function(ref) {
    var onClick = ref.onClick;
    return [_vm.can(_vm.permissions.getdata) ? _c("a-button", { staticClass: "ml-2", attrs: { "loading": _vm.isGettingData, "type": "primary" }, on: { "click": _vm.getData } }, [_vm._v("Get Data")]) : _vm._e(), _c("a-button", { staticClass: "ml-2", attrs: { "type": "primary" }, on: { "click": onClick } }, [_vm._v("Add New")])];
  } }, { key: "action", fn: function(ref) {
    var edit = ref.edit;
    var del = ref.del;
    var record = ref.record;
    return [_c("a-space", [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Edit")];
    }, proxy: true }], null, true) }, [_c("a-button", { attrs: { "icon": "edit", "size": "small", "type": "link" }, on: { "click": function($event) {
      return edit(record);
    } } })], 1) : _vm._e(), _vm.can(_vm.permissions.del) ? _c("a-tooltip", { scopedSlots: _vm._u([{ key: "title", fn: function() {
      return [_vm._v("Delete")];
    }, proxy: true }], null, true) }, [_c("a-button", { staticClass: "mr-2", attrs: { "icon": "delete", "size": "small", "type": "link" }, on: { "click": function($event) {
      return del(record);
    } } })], 1) : _vm._e()], 1)];
  } }]) }, [_c("text-field", { key: "Country", attrs: { "data-index": "country", "title": "Country", "sorter": true } }), _c("text-field", { key: "Version", attrs: { "data-index": "version", "title": "Version", "sorter": true } }), _c("text-field", { key: "FinYear", attrs: { "data-index": "finYear", "title": "Fin Year (Cal)", "sorter": true } }), _c("text-field", { key: "Comment", attrs: { "data-index": "comment", "title": "Comment", "sorter": true } }), _c("text-field", { key: "DraftUploadDate", attrs: { "data-index": "draftUploadDate", "title": "Draft Upload Date", "parse": _vm.parseDate, "sorter": true } }), _c("text-field", { key: "PublishDate", attrs: { "data-index": "publishDate", "title": "Publish Date", "sorter": true, "parse": _vm.parseDate } }), _c("text-field", { key: "PublishFlag", attrs: { "data-index": "publishFlag", "title": "Publish Flag", "sorter": true } }), _c("text-field", { key: "Base", attrs: { "data-index": "base", "title": "Base", "sorter": true } }), _c("text-field", { key: "ExistingBase", attrs: { "data-index": "existingBase", "title": "Existing Base", "sorter": true } }), _c("text-field", { key: "ForecastCycle", attrs: { "data-index": "forecastCycle", "title": "Forecast Cycle", "sorter": true } }), _c("text-field", { key: "ForecastingType", attrs: { "data-index": "forecastingType", "title": "Forecasting Process", "sorter": true } }), _c("text-field", { key: "BaseLock", attrs: { "data-index": "baseLock", "title": "Base Lock", "sorter": true } })], 1)], 1);
};
var staticRenderFns$1 = [];
var ListMaintainVersions_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".country-filter[data-v-6b460de3]{width:160px}.search-input[data-v-6b460de3]{width:230px}.ant-checkbox+span[data-v-6b460de3]{padding:0}.list[data-v-6b460de3] .ant-checkbox+span{padding:0}\n")();
const COUNTRY_NAME = {
  "": "All",
  AU: "Australia",
  NZ: "New Zealand",
  JPN: "Japan",
  KOR: "Korea"
};
const __vue2_script$1 = {
  name: "ListMaintainVersions",
  inject: ["apiUrl", "crud", "can"],
  data() {
    return {
      permissions: {
        del: DEL_ITEM_PERMISSION,
        maintain: MAINTAIN_PERMISSION,
        getdata: GETDATA_PERMISSION
      },
      countries: [],
      configDel: {
        sourceLabel: "Maintain Versions/Iterations",
        icon: "delete"
      },
      isGettingData: false
    };
  },
  created() {
    this.getCountries();
  },
  methods: {
    getCountries() {
      this.axios.get(`${this.apiUrl}/web-analytics/common/profit-country`).then((res) => {
        let { data: countries } = res;
        this.countries = countries.map((country) => ({
          ...country,
          countryName: COUNTRY_NAME[country.country]
        }));
      });
    },
    generateCountryLabel(option) {
      const { country, countryName } = option;
      if (!country)
        return countryName;
      if (!countryName)
        return country;
      return `${country} - ${countryName}`;
    },
    parseDate(date) {
      const mDate = this.$moment.parseZone(date);
      return mDate.isValid() ? mDate.format("DD/MM/YYYY hh:mm:ss A") : "";
    },
    onCountryFilterChange(country) {
      this.crud.deleteFilter("IsInactive");
      if (!country.value) {
        this.crud.deleteFilter("Country");
      } else {
        this.crud.setFilter("Country", {
          operator: "Contains",
          value: country.value
        });
      }
      const { pageSize } = this.crud.getPagination();
      this.crud.setPagination({ page: 1, pageSize });
      this.crud.fetchList();
    },
    async getData() {
      this.isGettingData = true;
      try {
        const res = await this.axios.put(`${this.apiUrl}/web-analytics/version-controls/profit-manual-fcst`);
        this.$notification.success({ message: res.data.message });
        await this.crud.fetchList();
      } catch (error) {
        this.$notification.error({
          message: "Something went wrong, please try again"
        });
      } finally {
        this.isGettingData = false;
      }
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "6b460de3", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListMaintainVersions = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "list-maintain-versions" }, [_c("bread-crumb", { attrs: { "items": _vm.itemsMenu } }), _c("resource", { attrs: { "name": "web-analytics.version-controls", "api-url": _vm.apiUrl, "create-route": "/web-analytics/maintain-versions/create", "edit-route": "/web-analytics/maintain-versions/:id", "page": _vm.page, "resource-id-name": "iterationId" } }, [_c("list-maintain-versions")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListMaintainVersions
  },
  data() {
    return {
      page: WEB_ANALYTICS_VERSION,
      apiUrl,
      itemsMenu: [
        {
          key: "profitability",
          title: "Profitability",
          path: ""
        },
        {
          key: "maintain-versions",
          title: "Maintain Versions/Iterations",
          path: "/web-analytics/maintain-versions"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
